import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/fonts.css';
// import './src/styles/slick.css';
// import './src/styles/slick-theme.css';
const cookies = new Cookies();
const sendPageView = (page) => {
  if (typeof window !== 'undefined' && window && window.gtag) {
    // console.log(
    //   'TRACKING PAGEVIEW',
    //   page,
    //   window.referrer.country,
    //   window.referrer.locale,
    // );
    window.gtag('set', 'pageName', page);
    window.gtag('set', 'pageType', 'nodfrom2050ecard_module');
    window.gtag('set', 'dimension84', 'nodfrom2050ecard_module');
    window.gtag('set', 'dimension5', window.referrer.country);
    window.gtag('set', 'dimension4', 'site');
    window.gtag('set', 'dimension3', window.referrer.locale);
    window.gtag('set', 'dimension2', 'nodfrom2050.ca');
    window.gtag(
      'set',
      'dimension1',
      'functional:1|analytics:1|customization:1|advertising:1',
    );
    window.gtag('config', window.referrer.code, { page_path: page });
    window.gtag(`send`, {
      hitType: `pageview`,
      page,
      pageName: page,
      pageType: 'nodfrom2050ecard_module',
    });
    console.log('*******sendPageView**********', {
      hitType: `pageview`,
      page,
      pageName: page,
      pageType: 'nodfrom2050ecard_module',
    });
  }
};

const loadGTAG = (id) => {
  console.log('loading gtag', id);
  // const script = document.createElement('script');
  // script.src = `https://www.googletagmanager.com/gtag/js`;
  // script.async = true
  // window.dataLayer = window.dataLayer || [];
  // function gtag() {
  //   dataLayer.push(arguments);
  // }
  // gtag('js', new Date());

  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.setAttribute('async', 'true');
  newScript.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${id}`,
  );
  const dlScript = document.createElement('script');

  dlScript.appendChild(
    document.createTextNode('window.dataLayer = window.dataLayer || [];'),
  );
  dlScript.appendChild(
    document.createTextNode('function gtag(){dataLayer.push(arguments);}'),
  );
  dlScript.appendChild(document.createTextNode("gtag('js', new Date());"));
  document.documentElement.firstChild.appendChild(newScript);
  document.body.appendChild(dlScript);
};

const googleAnalytics = {
  // trackingId: 'UA-123456789-XX',
  trackingIdsByLocale: [
    {
      subdomain: 'eng-ca',
      code: 'G-72FZB8D0CK',
      country: 'ca',
      locale: 'eng-ca',
    },
  ],
  // Setting this parameter is optional
  anonymize: true,
  cookieName: 'gtm_cookie_consent',
  // dataLayerName: 'gtm_cookie_consent',
};

window.initGoogleAnalytics = () => {
  let localeBasedId = null;
  if (
    googleAnalytics.trackingIdsByLocale &&
    googleAnalytics.trackingIdsByLocale.length > 0 &&
    !window.gtag
  ) {
    const tids = googleAnalytics.trackingIdsByLocale;
    const { referrer } = document;
    // get the pathname (en-us, es-es, etc)
    // let subdomain = 'https://us.louisvuitton.com/eng-us/magazine/articles/holiday-ecard-20#'
    let subdomain = 'https://nodfrom2050.ca'.split('/')[2].split('.')[0];

    const pathname =
      window.location.pathname.split(`/`).join(``) === ``
        ? `eng-ca`
        : window.location.pathname.split(`/`).join(``);

    // get a list o the tids in the system
    if (pathname && tids.find((obj) => obj.subdomain === pathname)) {
      const locale = tids.find((obj) => obj.subdomain === pathname);
      localeBasedId = locale.code;
    }

    if (referrer !== '' && (pathname === 'eng-ca' || pathname === '')) {
      // adjust the referrer based on the referring country iin canada / us
      subdomain = referrer.split('/')[2].split('.')[0];
      // console.log(
      //   tids,
      //   subdomain,
      //   tids.find((obj) => obj.subdomain === subdomain),
      // );
      window.referrer = tids.find((obj) => obj.subdomain === subdomain);
    } else {
      // set the rferrer based on the path
      // window.referrer = tids.filter(locale => locale.locale === pathname)[0];
      // console.log(
      //   tids,
      //   pathname,
      //   tids.find((obj) => obj.subdomain === pathname),
      // );
      window.referrer = tids.find((obj) => obj.subdomain === pathname);
    }

    if (!window.referrer || window.referrer == '') window.referrer = tids[0];
    const urlParams = new URLSearchParams(window.location.search);
    const dispatchCountry = urlParams.get('dispatchCountry');

    window.referrer.dispatchCountry =
      dispatchCountry && dispatchCountry !== ''
        ? dispatchCountry.toLowerCase()
        : null;
  }

  if (
    true
    // cookies.get(googleAnalytics.cookieName) === `true`
    // validGATrackingId(options)
  ) {
    // ReactGA.initialize(localeBasedId || googleAnalytics.trackingId);
    if (!window.gtag) {
      loadGTAG(localeBasedId || googleAnalytics.trackingId);

      if (typeof window !== 'undefined' && window && window.gtag) {
        // console.log(
        //   'TRACKING PAGEVIEW',
        //   page,
        //   window.referrer.country,
        //   window.referrer.locale,
        // );
        window.gtag('set', 'pageName', 'nodfrom2050ecard_step_1');
        window.gtag('set', 'pageType', 'nodfrom2050ecard_module');
        window.gtag('set', 'dimension84', 'nodfrom2050ecard_module');
        if (window.referrer.dispatchCountry)
          window.gtag('set', 'dimension7', window.referrer.dispatchCountry);
        window.gtag('set', 'dimension5', window.referrer.country);
        window.gtag('set', 'dimension4', 'site');
        window.gtag('set', 'dimension3', window.referrer.locale);
        window.gtag('set', 'dimension2', 'nodfrom2050.ca');
        window.gtag(
          'set',
          'dimension1',
          'functional:1|analytics:1|customization:1|advertising:1',
        );
        window.gtag('config', window.referrer.code, {
          page_path: 'nodfrom2050ecard_step_1',
        });
      }
      // window.gtag(`send`, {
      //   hitType: `pageview`,
      //   page: 'nodfrom2050ecard_step_1',
      //   pageName: 'nodfrom2050ecard_step_1',
      //   pageType: 'nodfrom2050ecard_module',
      // });
      window.gtag('event', 'page_view', {
        page_path: 'nodfrom2050ecard_step_1',
        page_location: '',
        page_title: 'Introduction',
      });
    }

    window.GoogleAnalyticsIntialized = true;
  }
};
